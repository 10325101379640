/**
 * Nav CSS File
 * Contains all navigation related styles
 *
 * @author         Flurin Dürst
 * @version        1.1
 * @since          WPegg 0.1
 *
 */


@import 'vars';

/* TOP
/===================================================== */

.top {
	position: fixed;
	top: 0;
	width: 100%;
	height: 10rem;
	z-index: 10;
	background-color: $element;
	box-shadow: $shadow-material-soft-s1;
	padding-right: calc((100vw - 1000px) / 2);
	padding-left: calc((100vw - 1000px) / 2);
	@include vpw_max(1230px) {
		padding-left: 10%;
		padding-right: 10%;
	}
	// until 800
	.logo {
		width: 7.4rem;
		height: 9rem;
		margin: .5rem;
		background-image: url('../images/logo.png');
		background-repeat: no-repeat;
		background-size: contain;
		float: left;
	}
}


/* NAV
/===================================================== */

nav {
	margin: 5rem 0;
    font-size: 2rem;
    font-weight: 700;
    text-align: right;
    float: right;
	ul {
		margin: 0;
		padding: 0;
	}
	li {
		display: inline-block;
		list-style-type: none;
		a {
			color: $nav;
		}
		a:hover {
			color: $nav-hover;
		}
		&.current_page_item,
		&.current_page_parent,
		&.current_page_item a,
		&.current_page_parent a {
			color: $nav-active;
		}
		+ li:before{
			padding: 0 1.5rem;
			color: $nav-spacer;
			content: ' | ';
		}
	}
}

/* MOBILE NAV
/===================================================== */

@include mobile {
	.top {
		box-shadow: 0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12);
		.mobilenav_trigger {
			display: block;
			position: absolute;
			right: 0;
			width: 6rem;
			height: 6rem;
			background-image: url('../images/svg/menu.svg');
			background-repeat: no-repeat;
			background-position: center;
			background-size: 5rem;
			cursor: pointer;
			transition: none;
			&.close {
				position: fixed;
				z-index: 999;
				background-image: url('../images/svg/close.svg');
				background-size: 3rem;
			}
		}
	}
	#nav_mobile {
		position: fixed;
		z-index: 250;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		overflow: scroll;
		opacity: 1;
		background-color: white;
		transition: .5s;
		pointer-events: initial;

		&.menu_hidden {
			opacity: 0;
			pointer-events: none;
		}
		ul {
			padding: 0;
			li {
				float: none;
				width: 100%;
				padding: 2rem 0;
				font-size: 4rem;
				list-style-type: none;
				text-align: center;
				+ li:before{
					padding: 0;
					content: '';
				}
			}
		}
	}
}
