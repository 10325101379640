/**
 * General CSS File
 * Contains re-usable classes and settings
 * This file is not meant to be changed
 *
 * @author         Flurin Dürst
 * @version        1.1
 * @since          WPegg 0.1
 *
 */


@import 'vars';

/* GENERAL
/------------------------*/
*,
*::before,
*::after {
	box-sizing: border-box;
	margin: 0;

	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

:focus {
	outline: 0;
}

body,
html {
	max-width: 100vw;
	height: 100%;
	overflow-x: hidden;
	margin: 0;
	font-family: $ff_text;
	font-size: $base_size;
	color: $text;
	background-color: #f2f2f2;
	@include vpw_min(2000px) {
		font-size: $size_2000;
	}
	@include vpw_max(1999px) {
		font-size: $size_1800;
	}
	@include vpw_max(1800px) {
		font-size: $size_1800;
	}
	@include vpw_max(1600px) {
		font-size: $size_1600;
	}
	@include vpw_max(1400px) {
		font-size: $size_1400;
	}
	@include vpw_max(1200px) {
		font-size: $size_1200;
	}
	@include vpw_max(1000px) {
		font-size: $size_1000;
	}
	@include mobile {
		font-size: $size_mobile;
	}
}

:focus {
	outline: 0;
}


/* TYPO
/------------------------*/

address {
	font-style: normal;
}

h1,
h2,
h3,
h4,
h5 {
	margin: 0;
	padding: 0;
}

/* LINKS
/------------------------*/
a {
	color: $link;
	text-decoration: none;
	&:hover {
		color: $link-hover;
	}
}

/* CUSTOM CLASSES
/------------------------*/
.noscroll {
	overflow: hidden;
}

.ff {
	// used in flexfill()
	height: 0;
	border: none;
}

.dn {
	display: none !important;
}
.db {
	display: block !important;
}
.df {
	display: flex !important;
}

.hidden {
	opacity: 0;
	transition: .1s;
}
.shown {
	opacity: 1;
}


/* FIXES
/------------------------*/
.fcf:after {
	display: table;
	clear: both;
	content: '';
}


/* ADMIN CLASSES
/------------------------*/
#wpadminbar {
	position: absolute;
	top: initial !important;
	bottom: 0 !important;
	left: 0;
	opacity: .1;
	&:hover {
		opacity: 1;
	}
	// Hide WP-Admin Bar on Mobile
	@include vpw_max(800px) {
		display: none;
	}
}


/* DEV CLASSES
/------------------------*/
.dev {
	margin: 5rem 0;
	border: 5px dashed #abb2c0;
	&.noborder {
		border: none;
	}
	&.green {
		border-color: #97c475;
	}
	&.blue {
		border-color: #51b6c3;
	}
	&.orange {
		border-color: #cc9b62;
	}
	&.red {
		border-color: #e26b73;
	}
}
pre {
	margin: .1rem;
	padding: .3rem;
	font-family: Menlo, Consolas, 'DejaVu Sans Mono', monospace;
	font-size: 15px;
	color: #abb2c0;
	border-radius: 5px;
	background-color: #282c34;
	&.green {
		color: #97c475;
	}
	&.blue {
		color: #51b6c3;
	}
	&.orange {
		color: #cc9b62;
	}
	&.red {
		color: #e26b73;
	}
}
