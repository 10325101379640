/**
 * Content CSS File
 * Contains all content related styles
 *
 * @author         Flurin Dürst
 * @version        1.1
 * @since          WPegg 0.1
 *
 */


@import 'vars';

/* GENERAL/STRUCTURE
/===================================================== */

.wrapper {
	margin-top: 10rem;
}

.content {
	position: relative;
	width: 80%;
	max-width: 1000px;
	margin: auto;
	@include mobile {
		width: 100%;
		padding: 2rem;
	}
	section.img {
		float: left;
		width: 50%;
		padding: 0 2rem 2rem 0;
		@include mobile {
			float: none;
			width: 100%;
			padding: -2rem;
		}
	}
}

/* HEADINGS, PARAGRAPHS
/===================================================== */

h1 {
	margin: 0 0 .5rem 0;
	font-size: 4rem;
	.products.overview & {
		margin-top: 4rem;
	}
}

h2 {
	margin: 0 0 .5rem 0;
	font-size: 3.25rem;
	margin-top: 5rem;
	.team & {
		margin-top: .5rem;
	}
}

section, p {
	margin: 0 0 1rem 0;
	font-size: 2rem;
	line-height: normal;
}

/* PAGES
/===================================================== */

/* TEMPLATE - PAGE
/------------------------*/
.page {
	.teaser {
		width: 100%;
		height: 45rem;
		margin-bottom: 2rem;
		background-position: center;
		background-size: cover;
	}
	article {
		margin-bottom: 5rem;
		h1 {
			margin: .5rem 0;
		}
		h2 {
			margin: 0 0 1rem 0; // top space fix compensation
			line-height: 70%; // top-space fix
		}
		.postinfo {
			margin: 0 0 2rem 0;
			font-size: 1.5rem;
			color: $grey;
		}
		.readmore a {
			color: $brown;
			&:hover {
				opacity: .5;
			}
		}
	}
}


/* TEMPLATE - TEAM
/------------------------*/
.team {
	section {
		float: left;
		width: 49%;
		margin-top: 2%;
		@include mobile {
			float: none;
			width: 100%;
		}
		&:nth-child(even) {
			margin-right: 2%;
		}
		img {
			width: 100%;
		}
	}
}

/* TEMPLATE - PRODUKTE
/------------------------*/
.products {
	section img {
		width: 11.5%;
		height: auto;
		margin-right: 1%;
	}
}
/* FOOTER
/===================================================== */
// https://philipwalton.github.io/solved-by-flexbox/
// docu: http://bit.ly/1Lt1vzH

.flexsite {
	display: flex;
	flex-direction: column;
	height: 100%; /* 1, 3 */
}

.wrapper {
	flex: 1 0 auto; /* 2 */
	width: 100%;
	padding: var(--space) var(--space) 0;
}

.wrapper::after {
	display: block;
	visibility: hidden;
	height: 0;
	margin-top: var(--space);
	content: '\00a0'; /* &nbsp; */
}

.footer {
	width: 100%;
	height: 6rem;
	padding: 2.5rem;
	font-size: 1.5rem;
	color: $grey;
	background: $element;
	box-shadow: $shadow-material-s1;
	text-align: center;
}

/* HACKS
/------------------------*/

// lightbox
.lb-number {
	display: none !important; // disable "Image 1 of 2" Text
}
.lb-container {
	padding: 0 !important; // diable displaced white border
}

/* ADMIN
/------------------------*/
// frontend admin related

.editcontent {
	position: fixed;
	right: 0;
	bottom: 0;
	height: 4rem;
	padding: 2rem;
	color: white;
	background-color: black;
}
