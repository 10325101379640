/**
 * Responsive CSS File
 * Contains all responsive/fluid related styles, mixins, settings
 * This file is not meant to be changed
 *
 * @author         Flurin Dürst
 * @version        1.1
 * @since          WPegg 0.1
 *
 */


@import 'vars';

/* RESPONSIVE MIXINS
/===================================================== */

/* MIXIN BY BREAKPOINTS
/------------------------*/
// breakpoint-width defined in vars.scss

@mixin desktop {
  @media (min-width: #{$desktop}) {
    @content;
  }
}
@mixin mobile {
  @media (max-width: #{$desktop - 1px}) {
    @content;
  }
}

/* MIXIN BY PIXELS
/------------------------*/
// usage example:  { ... }
@mixin vpw_min($min) {
    @media (min-width: #{$min}) { @content; }
}
@mixin vpw_max($max) {
    @media (max-width: #{$max}) { @content; }
}
@mixin vpw($min: 0px, $max: 0px) {
    @media (min-width: #{$min}) and (max-width: #{$max}) { @content; }
}

/* MIXIN BY ASPECT RATIO
/------------------------*/
// usage example: asr_min(16,9) { ... }
@mixin asr_min($width: 16, $height: 9) {
    @media (min-aspect-ratio:  #{$width} / #{$height}) { @content; }
}
@mixin asr_max($width: 16, $height: 9) {
    @media (max-aspect-ratio:  #{$width} / #{$height}) { @content; }
}
